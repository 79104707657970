<!-- 投资者教育问题 -->
<template>
	<div>
		<navs navTag="about"></navs>
		<div class="region">
			<!-- 面包屑 -->
			<crumbs :crumbs="crumbs" @clkItem="clkItemd"></crumbs>
			<div class="rg-cotain">
				<!-- 侧边导航栏 -->
				<navleft :navs="navs" :tapItem="tapItem" @clkItem="clkItem" :navRqt="true"></navleft>
				<!-- 子组件渲染 -->
				<div class="chirn">
					<router-view />
				</div>
			</div>
			<!-- 底部 -->
			<bottom></bottom>
		</div>
	</div>
</template>

<script>
	import $ from "jquery"
	import qs from "qs"
	export default {
		data() {
			return {
				crumbs: ['首页', '关于我们', '中心介绍'],
				tapItem: 0,
				navs: [],
				routers: ['centerIntroduce', "centerCulture", "shareholder", "history", "new_file", "newsNotice",
					"contactUs",'newList'
				]
			}
		},
		created() {
			let index = parseInt(this.$router.history.current.query.index)
			this.tapItem = index
			this.LeftSidebar(7)
		},
		methods: {
			clkItem(index, text) {
				this.$set(this.crumbs, 2, text.catname);
				this.tapItem = index;
				this.$router.push({
					name: this.routers[index],
					query: {
						index
					}
				});
			},
			getCotain() {
				let letain = $(".chirn")
				let width = letain.outerWidth() / 1198 * 100 + 'px'
				letain.css("font-size", width)
			},
			clkItemd(index) {
				switch (index) {
					case 0:
						this.$router.push({
							name: "index"
						})
						break;
				}
			},
			//左边导航栏
			LeftSidebar(nav_id) {
				let url = this.$api.about.LeftSidebar
				let data = {
					nav_id
				}
				this.$axios.post(url,qs.stringify(data)).then(res=>{
					if(res.code) {
						this.navs = res.list
						console.log('this.navs',this.navs)
					}
				})
				
			}
		},
		mounted() {
			$(document).ready(() => {
				this.getCotain()
				$(window).resize(() => {
					this.getCotain()
				})
			})
		},
	}
</script>

<style lang="less" scoped>
	.chirn {
		background-color: #fff;
		padding: 30px;
		box-sizing: border-box;
		width: 100%;
		align-self: normal;

	}

	.region .rg-cotain {
		margin-bottom: 60px;
	}

	@media (max-width: 900px) {
		.region .rg-cotain {
			flex-direction: column;
		}
	}

	@media (max-width:768px) {
		.chirn {
			padding: 10px;
		}
	}
</style>
